import { createAction, props } from '@ngrx/store';
import { ServiceLocation } from '../../data-model/models/service-location.model';

const feature = '[Service Location]';

export const loadServiceLocations = createAction(`${feature} Load Service Locations`);
export const serviceLocationsLoaded = createAction(`${feature} Service Locations Loaded`, props<{ serviceLocations: ServiceLocation[] }>());

export const serviceLocationSelected = createAction(`${feature} Service Location Selected`, props<{ serviceLocation: ServiceLocation }>());

export const resetServiceLocationState = createAction(`${feature} Reset Service Location State`);
