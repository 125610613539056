import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, interval, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class NetworkStatusService {
  private readonly networkStatusSubject = new BehaviorSubject<boolean>(true);
  private readonly connectivityCheckUrl = 'https://www.gstatic.com/generate_204';
  private readonly connectivityCheckInterval = 60000;

  get networkStatus$(): Observable<boolean> {
    return this.networkStatusSubject.asObservable().pipe(distinctUntilChanged());
  }

  get isOnline(): boolean {
    return this.networkStatusSubject.value;
  }

  get isOffline(): boolean {
    return !this.isOnline;
  }

  constructor() {
    window.addEventListener('offline', () => this.updateNetworkStatus(false));
    window.addEventListener('online', () => this.updateNetworkStatus(true));

    this.startConnectivityValidation();
  }

  private updateNetworkStatus(isOnline: boolean): void {
    this.networkStatusSubject.next(isOnline);
    if (navigator.serviceWorker?.controller) {
      navigator.serviceWorker.controller.postMessage({ type: 'NETWORK_STATUS_UPDATE', isOnline });
    }
  }

  private startConnectivityValidation(): void {
    interval(this.connectivityCheckInterval)
      .pipe(
        startWith(0),
        switchMap(() => this.validateConnectivity()),
        filter(isOnline => isOnline !== this.networkStatusSubject.value)
      )
      .subscribe(isOnline => this.updateNetworkStatus(isOnline));
  }

  private validateConnectivity(): Observable<boolean> {
    return from(
      fetch(this.connectivityCheckUrl, {
        method: 'HEAD',
        cache: 'no-store',
        mode: 'no-cors',
      })
    ).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}

// import { Injectable } from '@angular/core';
// import { BehaviorSubject, Observable, from, interval, of } from 'rxjs';
// import { catchError, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

// @Injectable({ providedIn: 'root' })
// export class NetworkStatusService {
//   private readonly networkStatusSubject = new BehaviorSubject<boolean>(true);
//   private readonly connectivityCheckUrl = '/health';
//   private readonly connectivityCheckInterval = 60000;

//   get networkStatus$(): Observable<boolean> {
//     return this.networkStatusSubject.asObservable().pipe(distinctUntilChanged());
//   }

//   get isOnline(): boolean {
//     return this.networkStatusSubject.value;
//   }

//   get isOffline(): boolean {
//     return !this.isOnline;
//   }

//   constructor() {
//     window.addEventListener('offline', () => this.updateNetworkStatus(false));
//     window.addEventListener('online', () => this.updateNetworkStatus(true));

//     this.startConnectivityValidation();
//   }

//   private updateNetworkStatus(isOnline: boolean): void {
//     this.networkStatusSubject.next(isOnline);
//     if (navigator.serviceWorker.controller) {
//       navigator.serviceWorker.controller.postMessage({ type: 'NETWORK_STATUS_UPDATE', isOnline });
//     }
//   }

//   private startConnectivityValidation(): void {
//     interval(this.connectivityCheckInterval)
//       .pipe(
//         startWith(0),
//         switchMap(() => this.validateConnectivity()),
//         filter(isOnline => isOnline !== this.networkStatusSubject.value)
//       )
//       .subscribe(isOnline => this.updateNetworkStatus(isOnline));
//   }

//   private validateConnectivity(): Observable<boolean> {
//     return from(fetch(this.connectivityCheckUrl, { method: 'HEAD', cache: 'no-store' })).pipe(
//       map(response => response.ok),
//       catchError(() => of(false))
//     );
//   }
// }
