export class StringBuilder {
  private _value: string[];

  constructor(initialValue: string = '') {
    this._value = [initialValue];
  }

  append(value: string): StringBuilder {
    this._value.push(value);
    return this;
  }

  appendLine(value: string): StringBuilder {
    this._value.push(value + '\n');
    return this;
  }

  clear(): StringBuilder {
    this._value = [];
    return this;
  }

  toString(): string {
    return this._value.join('');
  }

  getLength(): number {
    return this._value.join('').length;
  }
}
