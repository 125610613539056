import { createReducer, on } from '@ngrx/store';
import { ActivityRecordVerificationStatus } from '../../data-model/enums/activity-record-verification-status.enum';
import { ActivityActions } from '../actions';
import { ActivityState } from '../state/activity.state';

export const initialActivityState: ActivityState = {
  activityRecords: [],
  activityRecord: null,
  numberOfActivityRecords: 0,
  verificationStatus: ActivityRecordVerificationStatus.UNVERIFIED,
  limit: 10,
  offset: 0,
  numberOfUnverifiedActivityRecords: 0,
  numberOfMissingActivityRecords: 0,
};

export const activityReducer = createReducer(
  initialActivityState,
  on(
    ActivityActions.activityRecordsLoaded,
    (state, action): ActivityState => ({ ...state, activityRecords: action.activityRecords, numberOfActivityRecords: action.numberOfActivityRecords })
  ),
  on(
    ActivityActions.setVerificationStatus,
    (state, action): ActivityState => ({
      ...state,
      activityRecords: initialActivityState.activityRecords,
      numberOfActivityRecords: initialActivityState.numberOfActivityRecords,
      verificationStatus: action.verificationStatus,
      limit: initialActivityState.limit,
      offset: initialActivityState.offset,
    })
  ),
  on(ActivityActions.setLimit, (state, action): ActivityState => ({ ...state, limit: action.limit })),
  on(ActivityActions.setOffset, (state, action): ActivityState => ({ ...state, offset: action.offset })),
  on(ActivityActions.resetStateAndPreserveActivityRecord, (state): ActivityState => {
    const activityRecord = { ...state.activityRecord };
    return { ...state, ...initialActivityState, activityRecord };
  }),
  on(
    ActivityActions.unverifiedActivityRecordsLoaded,
    (state, action): ActivityState => ({ ...state, numberOfUnverifiedActivityRecords: action.numberOfUnverifiedActivityRecords })
  ),
  on(
    ActivityActions.missingActivityRecordsLoaded,
    (state, action): ActivityState => ({ ...state, numberOfMissingActivityRecords: action.numberOfMissingActivityRecords })
  ),
  on(ActivityActions.setActivityRecord, (state, action): ActivityState => ({ ...state, activityRecord: action.activityRecord })),
  on(ActivityActions.resetActivityState, (state): ActivityState => ({ ...state, ...initialActivityState }))
);
