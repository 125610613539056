import { createAction, props } from '@ngrx/store';

const feature = '[Navigation]';

// non navigation effects will be mapped into the following actions in the navigation effects class
export const navigateFromReviewFormToReviewRejectedApprovedList = createAction(`${feature} Navigate From Review Form To Review Rejected Approved List`);
export const navigateFromNonReviewFormToInProgressMyApprovedList = createAction(`${feature} Navigate From Non Review Form To In Progress My Approved List`);
export const navigateFromNonReviewFormToMissingActivityTable = createAction(`${feature} Navigate From Non Review Form To Missing Activity Table`);
export const navigateFromCloseEventToInProgressMyApprovedList = createAction(`${feature} Navigate From Close Event To In Progress My Approved List`);

// review view -> non-review view -> review view
export const setIsRedirectFromReview = createAction(`${feature} Set Is Redirect From Review`, props<{ isRedirectFromReview: boolean }>());
export const setIsRedirectFromNonReview = createAction(`${feature} Set Is Redirect From Non Review`, props<{ isRedirectFromNonReview: boolean }>());

// activity list -> non-review-form -> activity list
export const setIsRedirectFromActvity = createAction(`${feature} Set Is Redirect From Activity`, props<{ isRedirectFromActivity: boolean }>());
export const setIsRedirectFromNonActivity = createAction(`${feature} Set Is Redirect From Non Non Activity`, props<{ isRedirectFromNonActivity: boolean }>());

// reset navigation state
export const resetNavigationState = createAction(`${feature} Reset Navigation State`);
