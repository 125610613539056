import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { debounceTime, finalize, first, Observable, startWith, takeUntil, tap, timer } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { CrashReportActions, CrashReportStructuralActions } from '../../../crash-report/store/actions';
import { LoadingActions } from '../../store/actions';

@Injectable({ providedIn: 'root' })
export class ActionsService {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>
  ) {}

  getCrashReportDetailUpdatedInBulk$(
    loaderId: string,
    debounceMs: number = 1500,
    takeUntilMs: number = 15000
  ): Observable<ReturnType<typeof CrashReportActions.crashReportDetailUpdated>> {
    return this.actions$.pipe(
      ofType(CrashReportActions.crashReportDetailUpdated),
      startWith(null),
      debounceTime(debounceMs),
      first(),
      tap(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId }))),
      takeUntil(timer(takeUntilMs)),
      finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
    );
  }

  get vehicleSwapped$(): Observable<ReturnType<typeof CrashReportStructuralActions.vehiclesSwapped>> {
    return this.actions$.pipe(ofType(CrashReportStructuralActions.vehiclesSwapped));
  }

  get itemNumberUpdated$(): Observable<ReturnType<typeof CrashReportActions.itemNumberUpdated>> {
    return this.actions$.pipe(ofType(CrashReportActions.itemNumberUpdated));
  }
}
