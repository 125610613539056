import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { NavigationState } from '../state/navigation.state';

export const selectNavigationState = (state: AppState) => state.navigation;

export const selectIsRedirectFromReview = createSelector(selectNavigationState, (state: NavigationState) => state.isRedirectFromReview);
export const selectIsRedirectFromNonReview = createSelector(selectNavigationState, (state: NavigationState) => state.isRedirectFromNonReview);

export const selectIsRedirectFromActivity = createSelector(selectNavigationState, (state: NavigationState) => state.isRedirectFromActvity);
export const selectIsRedirectFromNonActivity = createSelector(selectNavigationState, (state: NavigationState) => state.isRedirectFromNonActivity);

export const selectIsRedirect = createSelector(
  selectIsRedirectFromReview,
  selectIsRedirectFromNonReview,
  selectIsRedirectFromActivity,
  selectIsRedirectFromNonActivity,
  (isRedirectFromReview, isRedirectFromNonReview, isRedirectFromActivity, isRedirectFromNonActivity) =>
    isRedirectFromActivity || isRedirectFromNonActivity || isRedirectFromReview || isRedirectFromNonReview
);
