import { createAction, props } from '@ngrx/store';
import { RuleSet } from '../../data-model/types/rule-set.type';

const feature = '[Rule Set]';

export const loadRuleSet = createAction(`${feature} Load Rule Set`);
export const ruleSetLoaded = createAction(`${feature} Rule Set Loaded`, props<{ ruleSet: RuleSet; version: number }>());

export const loadDefaultRuleSet = createAction(`${feature} Load Default Rule Set`);
export const defaultRuleSetLoaded = createAction(`${feature} Default Rule Set Loaded`, props<{ ruleSet: RuleSet; version: number }>());

export const resetRuleSetState = createAction(`${feature} Reset Rule Set State`);
export const resetRuleSetStateAndPreserveDefaultRuleSet = createAction(`${feature} Reset Rule Set State And Preserve Default Rule Set`);
