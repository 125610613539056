import { createAction, props } from '@ngrx/store';
import { OssUnit } from '../../data-model/models/oss-unit.model';

const feature = ['Unit'];

export const loadUnits = createAction(`${feature} Load Units`);
export const unitsLoaded = createAction(`${feature} Units Loaded`, props<{ units: OssUnit[] }>());

export const assignUnit = createAction(`${feature} Assign Unit`, props<{ unit: OssUnit }>());
export const unitAssigned = createAction(`${feature} Unit Assigned`, props<{ unit: OssUnit; assignmentId: number }>());

export const resetUnitState = createAction(`${feature} Reset Unit State`);
