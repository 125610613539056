export enum FormItemType {
  BUTTON_GROUP = 'BUTTON_GROUP',
  BUTTON_GROUP_MULTI = 'BUTTON_GROUP_MULTI',
  DATE = 'DATE',
  ECRASH_REF = 'ECRASH_REF',
  ECRASH_REF_MULTI = 'ECRASH_REF_MULTI',
  HELP = 'HELP',
  HIDDEN = 'HIDDEN',
  IMAGE = 'IMAGE',
  MAP = 'MAP',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  SCANNER = 'SCANNER',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  TIME = 'TIME',
}

export function getFormItemType(type: string): FormItemType {
  switch (type) {
    case 'button-group':
      return FormItemType.BUTTON_GROUP;

    case 'button-group-multi':
      return FormItemType.BUTTON_GROUP_MULTI;

    case 'date':
      return FormItemType.DATE;

    case 'ecrash-ref':
      return FormItemType.ECRASH_REF;

    case 'ecrash-ref-multi':
      return FormItemType.ECRASH_REF_MULTI;

    case 'help':
      return FormItemType.HELP;

    case 'hidden':
      return FormItemType.HIDDEN;

    case 'image':
      return FormItemType.IMAGE;

    case 'map':
      return FormItemType.MAP;

    case 'number':
      return FormItemType.NUMBER;

    case 'phone':
      return FormItemType.PHONE;

    case 'scanner':
      return FormItemType.SCANNER;

    case 'text':
      return FormItemType.TEXT;

    case 'textarea':
      return FormItemType.TEXTAREA;

    case 'time':
      return FormItemType.TIME;

    default:
      return null;
  }
}
