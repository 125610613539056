import { createAction, props } from '@ngrx/store';

const feature = '[Loading]';

export const showLoadingIndicator = createAction(`${feature} Show Loading Indicator`, props<{ message?: string }>());
export const hideLoadingIndicator = createAction(`${feature} Hide Loading Indicator`);

export const showLoadingIndicatorWithId = createAction(`${feature} Show Loading Indicator With ID`, props<{ loaderId: string; message?: string }>());
export const hideLoadingIndicatorWithId = createAction(`${feature} Hide Loading Indicator With ID`, props<{ loaderId: string }>());

export const resetLoadingState = createAction(`${feature} Reset Loading State`);
