import { createSelector } from '@ngrx/store';
import { AppState } from '../../../../store/state/app.state';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { SupervisorCrashReportSummary } from '../../data-model/models/supevisor-crash-report-summary.model';
import { CrashReportState } from '../state/crash-report.state';

export const selectCrashReportState = (state: AppState) => state.crashReport;

// selected crash report selectors
export const selectCrashReportSummary = createSelector(selectCrashReportState, (state: CrashReportState) => state.crashReportSummary);
export const selectCrashReportSummaryItemNumber = createSelector(selectCrashReportSummary, (crashReportSummary: CrashReportSummary) =>
  OssOptional.ofNullable(crashReportSummary)
    .map(summary => summary.nopd_item_num)
    .orElseNull()
);

export const selectSupervisorCrashReportSummary = createSelector(selectCrashReportState, (state: CrashReportState) => state.supervisorCrashReportSummary);
export const selectSupervisorCrashReportSummaryItemNumber = createSelector(
  selectSupervisorCrashReportSummary,
  (supervisorCrashReportSummary: SupervisorCrashReportSummary) =>
    OssOptional.ofNullable(supervisorCrashReportSummary)
      .map(summary => summary.nopd_item_num)
      .orElseNull()
);

export const selectCrashReportDetail = createSelector(selectCrashReportState, (state: CrashReportState) => state.crashReportDetail);

// used when creating a new crash report or loading an existing crash report selectors
export const selectNewCrashReportFormValue = createSelector(selectCrashReportState, (state: CrashReportState) => state.newCrashReportFormValue);
export const selectIsNewCrashReport = createSelector(selectCrashReportState, (state: CrashReportState) => state.isNewCrashReport);
export const selectLoaderId = createSelector(selectCrashReportState, (state: CrashReportState) => state.loaderId);

// crash report summaries selectors
const crashReportSummaryComparator = (
  crashReportSummary1: CrashReportSummary | SupervisorCrashReportSummary,
  crashReportSummary2: CrashReportSummary | SupervisorCrashReportSummary
) => {
  switch (true) {
    case CommonUtils.isNullOrUndefinedOrEmptyString(crashReportSummary1.date) && CommonUtils.isDefined(crashReportSummary2.date):
      return 1;
    case CommonUtils.isDefined(crashReportSummary1.date) && CommonUtils.isNullOrUndefinedOrEmptyString(crashReportSummary2.date):
      return -1;
    case crashReportSummary1.date < crashReportSummary2.date:
      return 1;
    case crashReportSummary1.date > crashReportSummary2.date:
      return -1;
    case crashReportSummary1.nopd_item_num < crashReportSummary2.nopd_item_num:
      return -1;
    case crashReportSummary1.nopd_item_num > crashReportSummary2.nopd_item_num:
      return 1;
    default:
      return 0;
  }
};

export const selectCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => {
  return [...state.crashReportSummaries].sort(crashReportSummaryComparator);
});
export const selectNumberOfCrashReportSummaries = createSelector(selectCrashReportState, (state: CrashReportState) => state.numberOfCrashReportSummaries);
export const selectCurrentLimit = createSelector(selectCrashReportState, (state: CrashReportState) => state.currentLimit);
export const selectCurrentOffset = createSelector(selectCrashReportState, (state: CrashReportState) => state.currentOffset);
export const selectCurrentTab = createSelector(selectCrashReportState, (state: CrashReportState) => state.currentTab);

// selectors for counts displayed in the horizontal nav bar
export const selectNumberOfNeedsReviewCrashReportSummaries = createSelector(
  selectCrashReportState,
  (state: CrashReportState) => state.numberOfNeedsReviewCrashReportSummaries
);
export const selectNumberOfMyRejectedCrashReportSummaries = createSelector(
  selectCrashReportState,
  (state: CrashReportState) => state.numberOfMyRejectedCrashReportSummaries
);
